
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

function Weinaru({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_CeSuo_Data : _r.dP_CeSuo_Datas.find((t: any) => t.XiangZhen_id == quyuId);

    const { cs_modi_shuliang,
        cs_gaizao_shuliang,
        cs_dabiao_shuliang,
        cs_butie_jine,
        mydc_canyudu,
        mydc_diaochawenjuan,
        mydc_manyidu,
        wnr_jihuabanqian,
        wnr_qitaxiangmu,
        wnr_buyuanyigai,
        wnr_yuqitagongyong,
        wnr_qitayuanyin,
        wancheng_2020qian,
        wancheng_2020,
        wancheng_2021,
        wancheng_2022,
        wancheng_2023hou,
        // wancheng,
        // gaizaozhong,
        gaizaomoshi_sangehua,
        gaizaomoshi_sanliantongzaoqi,
        gaizaomoshi_shangxiashui } = _d;
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">未纳入改厕任务户数</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative h-72">
                <Tu wnr_jihuabanqian={wnr_jihuabanqian} wnr_qitaxiangmu={wnr_qitaxiangmu} wnr_buyuanyigai={wnr_buyuanyigai} wnr_yuqitagongyong={wnr_yuqitagongyong} wnr_qitayuanyin={wnr_qitayuanyin} />
            </div>
            <div className='h-6'></div>
        </div>
    )
};

function Tu({ wnr_jihuabanqian, wnr_qitaxiangmu, wnr_buyuanyigai, wnr_yuqitagongyong, wnr_qitayuanyin }: any) {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0A6847'];
    const data02 = [
        { name: '计划搬迁', value: wnr_jihuabanqian },
        { name: '被其它项目覆盖', value: wnr_qitaxiangmu },
        { name: '农户不愿改', value: wnr_buyuanyigai },
        { name: '与他户公用无害化卫生厕所', value: wnr_yuqitagongyong },
        { name: '其他原因', value: wnr_qitayuanyin },
    ];
    const allTotal = wnr_jihuabanqian + wnr_qitaxiangmu + wnr_buyuanyigai + wnr_yuqitagongyong + wnr_qitayuanyin;

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            console.log('payload', payload, label)
            return (
                <div className="">
                    <p className="label">{`${payload[0].name}: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={360}>
                    <Tooltip content={<CustomTooltip />} />
                    <Legend layout='vertical' align='left' verticalAlign='middle' />
                    <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} label >
                        {data02.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div className="flex flex-col items-center absolute left-1/2 top-1/2 translate-x-[50%] translate-y-[-50%]">
                <div className="text-2xl text-purple-600">{allTotal}家</div>
                <div className="text-sm	">改造户厕</div>
            </div>
        </>
    )
}

export default Weinaru;