import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

import './index.css';
import { R } from './router';
import { NotifyProvider } from './components/useNotify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotifyProvider>
        <R />
      </NotifyProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

