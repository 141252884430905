import { useState } from "react";

const Reli = ({ hideBottom }: any) => {
    return (
        <>
            <div className=" relative shadow-xl xl:hidden" onClick={() => { hideBottom('reli', true) }}>
                <img className="h-14" src="/btn4.png" />
                <div className="absolute left-0 right-0 top-3.5 bottom-0 text-center text-xl text-white">治理分布</div>
            </div>
        </>
    )
}

export default Reli;