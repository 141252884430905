import { useState } from "react";

const Fenbu = ({ hideBottom }: any) => {
    return (
        <>
            <div className=" relative shadow-xl xl:hidden" onClick={() => { hideBottom('fenbu', true) }}>
                <img className="h-14" src="/btn2.png" />
                <div className="absolute left-0 right-0 top-3.5 bottom-0 text-center text-xl text-white">农户分布</div>
            </div>
        </>
    )
}

export default Fenbu;