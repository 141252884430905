import qs from 'qs';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { apiUrl, isDevelopment, tokenKey } from './global';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { LocalKey, LocalStorage } from "ts-localstorage";
import { useNotify } from './components/useNotify';

function App() {
  return <div>App Index</div>
}

export default App;
