

function Minyidiaocha({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_CeSuo_Data : _r.dP_CeSuo_Datas.find((t: any) => t.XiangZhen_id == quyuId);

    const { cs_modi_shuliang,
        cs_gaizao_shuliang,
        cs_dabiao_shuliang,
        cs_butie_jine,
        mydc_canyudu,
        mydc_diaochawenjuan,
        mydc_manyidu,
        wnr_jihuabanqian,
        wnr_qitaxiangmu,
        wnr_buyuanyigai,
        wnr_yuqitagongyong,
        wnr_qitayuanyin,
        wancheng_2020qian,
        wancheng_2020,
        wancheng_2021,
        wancheng_2022,
        wancheng_2023hou,
        // wancheng,
        // gaizaozhong,
        gaizaomoshi_sangehua,
        gaizaomoshi_sanliantongzaoqi,
        gaizaomoshi_shangxiashui } = _d;
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">民意调查</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="h-64 xl:h-44 relative">
                <div className=" flex flex-col items-center absolute left-0 top-20 xl:top-10 right-0">
                    <div className="text-2xl text-yellow-400">{mydc_canyudu}%</div>
                    <div className="text-sm	">参与度</div>
                </div>
                <div className=" flex flex-col items-center absolute right-10 bottom-10">
                    <div className="text-2xl text-purple-600">{mydc_diaochawenjuan}家</div>
                    <div className="text-sm	">收集调查问卷</div>
                </div>
                <div className=" flex flex-col items-center absolute left-10 bottom-10">
                    <div className="text-2xl text-orange-400">{mydc_manyidu}%</div>
                    <div className="text-sm	">满意度</div>
                </div>
                <img src="/building.png" className=" z-0 absolute bottom-0 left-0 right-0 mx-auto" />
            </div>
            <div className='h-6'></div>
        </div>
    )
};

export default Minyidiaocha;