

function Weihu({ data, quyuId }: any) {
    console.log('data', data)
    console.log('quyuId', quyuId)
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.weiGuanCompanys : _r.weiGuanCompanys.filter((t: any) => t.XiangZhen_id == quyuId);
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">管维护主体处理情况</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative mt-2 pb-10 max-h-[160px] overflow-y-auto mScroll">
                <table className="w-full border-collapse text-left text-sm text-white-500">
                    <thead className="bg-slate-800/50	">
                        <tr>
                            <th scope="col" className="px-6 py-2 font-medium ">序号</th>
                            <th scope="col" className="px-6 py-2 font-medium ">管维护主体名称</th>
                            <th scope="col" className="px-6 py-2 font-medium ">处理数量</th>
                            <th scope="col" className="px-6 py-2 font-medium ">综合评价</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {_d.map(({ CompanyName, ChuLi_Count, PingJia }: any, index: number) => {
                            return (
                                <tr className="even:bg-slate-800/40">
                                    <th className="px-6 py-2  ">{index + 1}</th>
                                    <td className="px-6 py-2">{CompanyName}</td>
                                    <td className="px-6 py-2">{ChuLi_Count}</td>
                                    <td className="px-6 py-2">{PingJia}</td>
                                </tr>
                            )
                        })}
                        {/* <tr className="even:bg-slate-800/40">
                            <th className="px-6 py-2  ">1</th>
                            <td className="px-6 py-2">XX管维护公司</td>
                            <td className="px-6 py-2">133</td>
                            <td className="px-6 py-2">5</td>
                        </tr>
                        <tr className="even:bg-slate-800/40">
                            <th className="px-6 py-2 ">2</th>
                            <td className="px-6 py-2">XX管维护公司</td>
                            <td className="px-6 py-2">133</td>
                            <td className="px-6 py-2">5</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            <div className='h-6'></div>
        </div>
    )
};

export default Weihu;