

function Gaizaomoshi({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_CeSuo_Data : _r.dP_CeSuo_Datas.find((t: any) => t.XiangZhen_id == quyuId);

    const { cs_modi_shuliang,
        cs_gaizao_shuliang,
        cs_dabiao_shuliang,
        cs_butie_jine,
        mydc_canyudu,
        mydc_diaochawenjuan,
        mydc_manyidu,
        wnr_jihuabanqian,
        wnr_qitaxiangmu,
        wnr_buyuanyigai,
        wnr_yuqitagongyong,
        wnr_qitayuanyin,
        wancheng_2020qian,
        wancheng_2020,
        wancheng_2021,
        wancheng_2022,
        wancheng_2023hou,
        // wancheng,
        // gaizaozhong,
        gaizaomoshi_sangehua,
        gaizaomoshi_sanliantongzaoqi,
        gaizaomoshi_shangxiashui } = _d;
    // const bg1 = process.env.PUBLIC_URL + '/sg-mk1.png';
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">改造模式情况统计</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="h-64 flex justify-around py-6 ">
                <div className="animate-bounce_1 flex flex-col items-center  justify-center " style={{ width: '116px', height: '116px', backgroundImage: 'url(/sg-mk1.png)', backgroundSize: 'cover' }}>
                    <div className="text-2xl text-yellow-400">{gaizaomoshi_sangehua}</div>
                    <div className="text-sm	">三格化粪池式</div>
                </div>
                <div className="animate-bounce_2 flex flex-col items-center   justify-center  " style={{ width: '116px', height: '116px', backgroundImage: 'url(/sl-mk1.png)', backgroundSize: 'cover' }}>
                    <div className="text-2xl text-purple-600">{gaizaomoshi_sanliantongzaoqi}</div>
                    <div className="text-sm	">三联通沼气池</div>
                </div>
                <div className="animate-bounce_3 flex flex-col items-center   justify-center " style={{ width: '116px', height: '116px', backgroundImage: 'url(/sxsd-mk1.png)', backgroundSize: 'cover' }}>
                    <div className="text-2xl text-orange-400">{gaizaomoshi_shangxiashui}</div>
                    <div className="text-sm	">完成上下水道</div>
                </div>
                {/* <img src="/building.png" className=" z-0 absolute bottom-0 left-0 right-0 mx-auto" /> */}
            </div>
        </div>
    )
};

export default Gaizaomoshi;