import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  IsOverView,
  ListView1,
  LoadingView,
  apiUrl,
  decodeToken,
} from "./global";
import { useInfiniteQuery, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroller";
import { useParams } from "react-router-dom";

import { mapKey, mapSecretKey } from "./global";
import dataJson from "./data.json";
import dataXianJson from "./dataXian.json";
import AMapLoader from "@amap/amap-jsapi-loader";
import Geming from "./bk/geming";
import Minyidiaocha from "./bk/minyidiaocha";
import Weinaru from "./bk/weinaru";
import Wancheng from "./bk/wancheng";
import Wanchengtongji from "./bk/wanchengtongji";
import Gaizaomoshi from "./bk/gaizaomoshi";
import Weihu from "./bk/weihu";
import Weihuzhuti from "./bk/weihuzhuti";
import Weihuzanbi from "./bk/weihuzanbi";
import Weihuxuqiutongji from "./bk/Weihuxuqiutongji";
import Weihuxuqiutongji2 from "./bk/Weihuxuqiutongji2";
import Quyu from "./components/quyu";
import Search from "./components/search";
import Fenbu from "./components/fenbu";
import Reli from "./components/reli";
import Cesuogeming from "./components/cesuogeming";
import Ceguanhu from "./components/ceguanhu";

let _AMap;
let map;
let defaultZoom;
let defaultCenter;
var heatmap;
let quyuMapObj = null;
let G_XiangZhens = null;

//多边形中心点
const getCenterPoint = (AMap, data) => {
  let lng = 0.0;
  let lat = 0.0;
  for (let i = 0; i < data.length; i++) {
    lng = lng + parseFloat(data[i].lng);
    lat = lat + parseFloat(data[i].lat);
  }
  lng = lng / data.length;
  lat = lat / data.length;
  return new AMap.LngLat(lng, lat);
};
//重新定位中心点
const reLocation = () => {
  try {
    //console.log('map', map)
    window.global.map.setZoomAndCenter(defaultZoom, defaultCenter, false, 200);
  } catch { }
};
//生成从minNum到maxNum的随机数
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}
function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const getPoints = async () => {
  let points = []
  for (var index in dataJson.features) {
    var item = dataJson.features[index];
    let geometry = item.geometry;

    let path = [];

    geometry.coordinates[0].forEach((coordinate) => {
      path.push(new _AMap.LngLat(coordinate[0], coordinate[1]));
    });

    let pathCenter = getCenterPoint(_AMap, path);
    if (item.properties["name"].length > 0) {
      var name = item.properties["name"];
      var center = [pathCenter.lng, pathCenter.lat];
      console.log("name", name);

      //Start
      var position = [];
      while (true) {
        if (position.length >= 400) break;
        if (position.length > 0 && (position.length % 100) == 0) {
          console.log("position.length", position.length);
        }
        await sleep(10)
        var lng_C = parseFloat(randomNum(-50000, 50000)) / 1000000.0
        await sleep(10)
        var lat_C = parseFloat(randomNum(-50000, 50000)) / 1000000.0
        var newPoint = [pathCenter.lng + lng_C, pathCenter.lat + lat_C];
        var isPointInRing = _AMap.GeometryUtil.isPointInRing(newPoint, path);
        if (isPointInRing) position.push(newPoint);
      }
      points.push({ name, center: center, position });
    }
  }
  document.body.innerText = JSON.stringify(points);
}

function BigScreen() {

  let [mapok, setMapok] = useState(false);
  let [polygons, setPolygons] = useState([]);


  useEffect(() => {
    window._AMapSecurityConfig = { securityJsCode: mapSecretKey };
    AMapLoader.load({
      key: mapKey, // 申请好的Web端开发者Key，首次调用 load 时必填
      // version: "2.1Beta", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      plugins: ["AMap.Geocoder", "Map3D"],
    })
      .then((AMap) => {
        _AMap = AMap;
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "乐山",
        });
        geocoder.getLocation("井研县", function (status, result) {
          if (status === "complete" && result.info === "OK") {
            //console.log('result', result)
            let { lng, lat } = result.geocodes[0].location;

            defaultZoom = 11;
            defaultCenter = [lng, lat];
            map = new AMap.Map("container", {
              //设置地图容器id
              mapStyle: "amap://styles/blue", //设置地图的显示样式
              viewMode: "3D",         //是否为3D地图模式
              // terrain: true,
              pitch: 45,
              zoom: defaultZoom, //初始化地图级别
              center: defaultCenter, //初始化地图中心点位置
              features: ["bg", "road", "building"],
              zooms: [11, 12, 13],
              dragEnable: false,
              // zoomEnable: false,
              scrollWheel: false,
              doubleClickZoom: false,
              touchZoom: false,
              rotateEnable: false,
            });

            //console.log('dataJson.features', dataJson)
            let _polygons = [];
            dataJson.features.forEach((item) => {
              //console.log(item.type)
              let geometry = item.geometry;
              if (geometry.type === "Polygon") {
                let path = [];
                geometry.coordinates[0].forEach((coordinate) => {
                  path.push(new AMap.LngLat(coordinate[0], coordinate[1]));
                });
                //console.log('fill', item.properties['fill'])
                let polygonArg = {
                  path: path,
                  fillColor: item.properties["fill"], // 多边形填充颜色
                  fillOpacity: item.properties["fill-opacity"],
                  borderWeight: item.properties["stroke-width"],
                  strokeColor: item.properties["stroke"],
                  strokeOpacity: item.properties["stroke-opacity"],
                };
                let polygon = new AMap.Polygon(polygonArg);
                map.add(polygon);
                _polygons.push(polygon);
                polygon.on("click", quyuClick);
                //添加高度面
                // var object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
                // map.add(object3Dlayer)
                // var height = -8000;
                // var color = '#46B1B8cc';//rgba
                // var wall = new AMap.Object3D.Wall({
                //   path: path,
                //   height: height,
                //   color: color
                // });
                // wall.transparent = true
                // object3Dlayer.add(wall)

                let pathCenter = getCenterPoint(AMap, path);
                // 创建纯文本标记
                if (item.properties["name"].length > 0) {
                  var name = item.properties["name"];
                  var center = [pathCenter.lng, pathCenter.lat];
                  var text = new AMap.Text({
                    text: name,
                    anchor: "center", // 设置文本标记锚点
                    style: {
                      "background-color": "transparent",
                      cursor: "default",
                      border: "none",
                      "font-size": "15px",
                      color: "#FFF",
                      "z-index": 999,
                    },
                    position: center,
                  });
                  text.setMap(map);
                  polygon.setExtData({ quyuName: item.properties["name"] });
                }
              }
            });
            setPolygons(_polygons);

            var _bounds = [];
            var bounds = dataXianJson.features[0].geometry.coordinates[0][0];
            for (var i in bounds) {
              _bounds.push(new AMap.LngLat(bounds[i][0], bounds[i][1]));
            }
            //添加高度面
            var object3Dlayer = new AMap.Object3DLayer({ zIndex: 1 });
            map.add(object3Dlayer)
            var height = -8000;
            var color = '#46B1B8cc';//rgba
            var wall = new AMap.Object3D.Wall({
              path: _bounds,
              height: height,
              color: color
            });
            wall.transparent = true
            object3Dlayer.add(wall)

            setMapok(true);
          }
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const [chooseType, setChooseType] = useState(1); //1:厕所革命 2:户厕围管护

  const [quyuId, setQuyuId] = useState(0);
  const [quyuName, setQuyuName] = useState('全县');
  const { data, isLoading, isError, error, isSuccess } = useQuery('GetBigScreenData', () => axios.get(apiUrl + '/Home/GetBigScreenData'));

  const [hideButtonMenu, setHideButtonMenu] = useState(false);
  const [showFenBu, setShowFenBu] = useState(false);
  const [circles, setCircles] = useState([]);

  const quyuClick = (eventName, handler, context) => {

    if (quyuMapObj != null) quyuMapObj.setOptions({ fillColor: '#057683', fillOpacity: 0.2 });

    if (quyuMapObj != eventName.target) {
      quyuMapObj = eventName.target;
      quyuMapObj.setOptions({ fillColor: 'orange', fillOpacity: 1 });

      // let { XiangZhens } = data.data.Result;
      // setQuyuId(XiangZhens.find(t => t.name == quyuMapObj.getExtData().quyuName).id);
      // setQuyuName(quyuMapObj.getExtData().quyuName);
      // setQuYu({ id: -1, name: quyuMapObj.getExtData().quyuName });

      const n = quyuMapObj.getExtData().quyuName;
      ((params) => {
        return setQuYu(params)
      })({ id: -1, name: n, text: false })
    }
    else {
      quyuMapObj = null;
      // setQuyuId(0);
      // setQuyuName('全县');
      // setQuYu({ id: 0, name: '全县' });

      ((params) => {
        return setQuYu(params)
      })({ id: 0, name: '全县', text: false })
    }
  }

  const setQuYu = ({ id, name, text = true }) => {
    console.log(id, name);
    if (id == -1) {
      setQuyuId(G_XiangZhens.find(t => t.name == name).id);
    }
    else {
      setQuyuId(id);
    }
    setQuyuName(name);
    // console.log('name', name)
    if (text) {
      for (var u in polygons) {
        var p = polygons[u];
        const n = p.getExtData().quyuName;
        // console.log('n', n)
        if (n == name) {
          if (quyuMapObj != null) quyuMapObj.setOptions({ fillColor: '#057683', fillOpacity: 0.2 });
          quyuMapObj = p;
          quyuMapObj.setOptions({ fillColor: 'orange', fillOpacity: 1 });
          break;
        }
      }
    }
  }

  const hideBottom = (type, status) => {
    setHideButtonMenu(status);
    if (status) {
      if (type === 'fenbu') {
        // getPoints()
        setShowFenBu(status);

        //控制地图
        map.setZoomAndCenter(13, defaultCenter, true, 1000);
        loadFenBuData();
      }
      if (type === 'reli') {
        setShowFenBu(status);

        //控制地图
        map.setZoomAndCenter(13, defaultCenter, true, 1000);
        loadReLiData();
      }
    }
    else {
      if (showFenBu) {
        map.setZoomAndCenter(11, defaultCenter, true, 1000);

        map.remove(circles);
        try { heatmap.hide(); } catch { }
      }
      setShowFenBu(status);
    }
  }

  const loadFenBuData = () => {
    if (circles.length > 0) { map.add(circles); return; }
    axios.get(apiUrl + '/Home/GetFenBu')
      .then(async (res) => {
        // console.log(res.data.Result);
        let _circles = [];
        for (var i in res.data.Result) {
          var pos = res.data.Result[i];
          let circle = new _AMap.CircleMarker({ center: pos, radius: 4, fillColor: '#FF0000', fillOpacity: 1, strokeWeight: 0 })
          _circles.push(circle)
        }
        map.add(_circles);
        setCircles(_circles);
      })
  }
  const loadReLiData = () => {
    if (heatmap != null) { heatmap.show(); return; }

    axios.get(apiUrl + '/Home/GetFenBu')
      .then(async (res) => {
        // console.log(res.data.Result);
        let heatmapData = [];
        for (var i in res.data.Result) {
          var pos = res.data.Result[i];
          var _data = { lng: pos[0], lat: pos[1], count: 10 };
          heatmapData.push(_data);
        }

        map.plugin(["AMap.HeatMap"], function () {
          //初始化heatmap对象
          heatmap = new _AMap.HeatMap(map, {
            radius: 20, //给定半径
            opacity: [0, 0.8]
            /*,
            gradient:{
                0.5: 'blue',
                0.65: 'rgb(117,211,248)',
                0.7: 'rgb(0, 255, 0)',
                0.9: '#ffea00',
                1.0: 'red'
            }
             */
          });
          console.log('heatmap', heatmap)
          //设置数据集：该数据为北京部分“公园”数据
          heatmap.setDataSet({
            data: heatmapData,
            max: 60
          });
        });

      })
  }


  if (isSuccess) {
    let { XiangZhens } = data.data.Result;
    G_XiangZhens = XiangZhens;
  }

  return (
    <div className="App">
      {isLoading &&
        <div className=" fixed left-0 top-0 right-0 bottom-0 bg-white/50 z-50 flex justify-center items-center">
          <span className="text-4xl text-slate-800">数据加载中...</span>
        </div>
      }
      {isError &&
        <div className=" fixed left-0 top-0 right-0 bottom-0 bg-white/50 z-50 flex justify-center items-center">
          <span className="text-4xl text-slate-800">Error: {`${error}`}</span>
        </div>
      }
      {/* {mapok && <V1 />} */}
      <div className='flex items-center justify-center fixed top-0 left-0 w-full h-30 z-10'>
        {/* <div className='text-xl pl-3 text-slate-300'>井研人居环境治理综合管理系统</div> */}
        <div className="pt-4">
          <img src="/logo_cs.png" width={740} alt="井研人居环境治理综合管理系统" />
        </div>
        {/* <div className="flex-1"></div> */}
      </div>
      <div id="container" className="map" style={{ width: '100%', height: '100vh', opacity: 0.8 }} >
      </div>
      {/* <div className='Location'>
        <div onClick={reLocation}>
          <svg t="1653625062784" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4893"  ><path d="M87.424 469.312A426.816 426.816 0 0 1 469.312 87.424V0h85.376v87.424a426.816 426.816 0 0 1 381.888 381.888H1024v85.376h-87.424a426.816 426.816 0 0 1-381.888 381.888V1024h-85.376v-87.424A426.816 426.816 0 0 1 87.424 554.688H0v-85.376h87.424z m424.576 384a341.312 341.312 0 1 0 0-682.624 341.312 341.312 0 0 0 0 682.624z m0-170.624a170.688 170.688 0 1 0 0-341.376 170.688 170.688 0 0 0 0 341.376z" p-id="4894"></path></svg>
        </div>
      </div> */}
      {!showFenBu && !isLoading && isSuccess &&
        <>
          <div className="z-30 absolute left-0 top-0 bottom-0 px-4 pt-28 text-white w-2/5 bg-[linear-gradient(to_right,#00000099,#00000005)] xl:hidden">
            <Geming data={data} quyuId={quyuId} />
            <Minyidiaocha data={data} quyuId={quyuId} />
            <Weinaru data={data} quyuId={quyuId} />
            <Wancheng data={data} quyuId={quyuId} />
            {/* <Wanchengtongji /> */}
            <Gaizaomoshi data={data} quyuId={quyuId} />
          </div>
          <div className="z-30 absolute right-0 top-0 bottom-0 px-4 pt-28 text-white w-2/5 bg-[linear-gradient(to_left,#00000099,#00000005)] xl:hidden">
            <Weihu data={data} quyuId={quyuId} />
            <Weihuzhuti data={data} quyuId={quyuId} />
            <Weihuzanbi data={data} quyuId={quyuId} />
            <Weihuxuqiutongji data={data} quyuId={quyuId} />
            <Weihuxuqiutongji2 data={data} quyuId={quyuId} />
          </div>


          {chooseType === 1 ?
            <>
              <div className="hidden z-30 absolute left-0 top-0 bottom-0 px-4 pt-28 text-white w-1/3 bg-[linear-gradient(to_right,#00000099,#00000005)] xl:block">
                <Geming data={data} quyuId={quyuId} />
                <Minyidiaocha data={data} quyuId={quyuId} />
                <Weinaru data={data} quyuId={quyuId} />
              </div>
              <div className="hidden z-30 absolute right-0 top-0 bottom-0 px-4 pt-28 text-white w-1/3 bg-[linear-gradient(to_left,#00000099,#00000005)] xl:block">
                <Wancheng data={data} quyuId={quyuId} />
                {/* <Wanchengtongji /> */}
                <Gaizaomoshi data={data} quyuId={quyuId} />
              </div>
            </>
            :
            <>
              <div className="hidden z-30 absolute left-0 top-0 bottom-0 px-4 pt-28 text-white w-1/3 bg-[linear-gradient(to_right,#00000099,#00000005)] xl:block">
                <Weihu data={data} quyuId={quyuId} />
                <Weihuzhuti data={data} quyuId={quyuId} />
                <Weihuzanbi data={data} quyuId={quyuId} />
              </div>
              <div className="hidden z-30 absolute right-0 top-0 bottom-0 px-4 pt-28 text-white w-1/3 bg-[linear-gradient(to_left,#00000099,#00000005)] xl:block">
                <Weihuxuqiutongji data={data} quyuId={quyuId} />
                <Weihuxuqiutongji2 data={data} quyuId={quyuId} />
              </div>
            </>
          }
        </>
      }
      {!isLoading &&
        <>
          <div className={hideButtonMenu ? "invisible z-40 absolute bottom-12 left-0 right-0 w-full flex  space-x-9 justify-center items-end" : "z-40 absolute bottom-12 left-0 right-0 w-full flex  space-x-9 justify-center items-end"}>
            <Quyu quyuId={quyuId} quyuName={quyuName} setQuYu={setQuYu} data={data} />

            <Fenbu hideBottom={hideBottom} />
            <Cesuogeming chooseType={chooseType} setChooseType={setChooseType} />

            <Search />

            <Reli hideBottom={hideBottom} />
            <Ceguanhu chooseType={chooseType} setChooseType={setChooseType} />

            <a className=" relative shadow-xl xl:fixed xl:right-5 xl:top-5 xl:opacity-60" target="_blank" href="https://jy.cs.admin.x37b.cc">
              <img className="h-14" src="/btn3.png" />
              <div className="absolute left-0 right-0 top-3.5 bottom-0 text-center text-xl text-white">管理后台</div>
            </a>

            <ErWeiMaView />

          </div>
          {hideButtonMenu &&
            <div className="animate-bottominbottom w-full rounded-lg fixed z-50 p-4 flex justify-center">
              <div className="relative">
                <div className="bg-white rounded-full shadow-xl p-0" onClick={() => { hideBottom("", false) }}>
                  <svg className="icon fill-red-700" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2272" width="100" height="100"><path d="M513.344 0a512 512 0 1 0 0 1024 512 512 0 0 0 0-1024z m226.048 674.624l-54.528 56.896-171.52-164.928-171.392 164.928-54.592-56.896L456.576 512 287.36 349.312l54.592-56.768 171.392 164.8 171.52-164.8 54.528 56.768L570.176 512l169.216 162.624z" fill="" p-id="2273"></path></svg>
                </div>
                <div className="animate-ping absolute top-4 left-4 right-4 bottom-4 -z-10 bg-red-700 rounded-full shadow-xl p-3">
                </div>
              </div>
            </div>}
        </>
      }
    </div>
  )
}

const ErWeiMaView = () => {
  const [show, setShow] = useState(false)
  return (
    <div class=' fixed top-8 right-10 xl:top-7 xl:right-56 cursor-pointer' onClick={() => {
      setShow(true)
      setTimeout(() => setShow(false), 5000);
    }}>
      <img className="rounded-lg transition-all" style={show ? { width: 180, height: 180 } : { width: 40, height: 40 }} src="/wx.jpg" />
    </div>
  )
}

export default BigScreen;
