
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Wancheng({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_CeSuo_Data : _r.dP_CeSuo_Datas.find((t: any) => t.XiangZhen_id == quyuId);


    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">完成情况</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative h-72 mt-4">
                <Tu _d={_d} />
            </div>
            <div className='h-6'></div>
        </div>
    )
};

function Tu({ _d }: any) {
    const { cs_modi_shuliang,
        cs_gaizao_shuliang,
        cs_dabiao_shuliang,
        cs_butie_jine,
        mydc_canyudu,
        mydc_diaochawenjuan,
        mydc_manyidu,
        wnr_jihuabanqian,
        wnr_qitaxiangmu,
        wnr_buyuanyigai,
        wnr_yuqitagongyong,
        wnr_qitayuanyin,
        wancheng_2020qian,
        wancheng_2020,
        wancheng_2021,
        wancheng_2022,
        wancheng_2023hou,
        // wancheng,
        // gaizaozhong,
        gaizaomoshi_sangehua,
        gaizaomoshi_sanliantongzaoqi,
        gaizaomoshi_shangxiashui } = _d;
    const data = [
        {
            name: '2020年前',
            amt: wancheng_2020qian,
        },
        {
            name: '2020',
            amt: wancheng_2020,
        },
        {
            name: '2021',
            amt: wancheng_2021,
        },
        {
            name: '2022',
            amt: wancheng_2022,
        },
        {
            name: '2023',
            amt: wancheng_2023hou,
        },
    ];

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="">
                    <p className="label">{`数量: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="1 3" /> */}
                <XAxis dataKey="name" stroke="#FFF" />
                <YAxis stroke="#FFF" />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <Bar barSize={15} name={'数量'} dataKey="amt" stackId="a" fill="#8884d8" />
            </BarChart>
        </ResponsiveContainer>

    )
}

export default Wancheng;