/* eslint-disable */
import axios from "axios";
import { useState } from "react";
import { useQuery } from "react-query";
import { Sgv1, apiUrl, isDevelopment, } from "../global";
import qs from "qs";
import { useNotify } from "./useNotify";

// @ts-nocheck
// @ts-ignore
// import { getLodop } from './LodopFuncs';

const Search = ({ }: any) => {
    const notify = useNotify();
    const [open, setOpen] = useState(false);
    const [val, setVal] = useState(!isDevelopment ? '' : '15983369295');
    // const [val, setVal] = useState('15983369295');

    const { data, isLoading, isError, error, isFetching, isRefetching, refetch } = useQuery(['GetCehu', val], () => axios.post(apiUrl + '/Home/GetCehu', qs.stringify({ Key: val })), { enabled: false });
    // console.log(isLoading, isError, error, isFetching, isRefetching)

    const submit = () => {
        if (val == '') {
            notify.show({ message: '请先输入内容', type: 'error' });
            return;
        }
        refetch();
    }

    return (
        <>
            <div className=" relative">
                <div className=" bg-white/90 rounded-full shadow-xl p-3" onClick={() => setOpen(true)}>
                    <svg className="icon" viewBox="0 0 1035 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8743" width="70" height="70"><path d="M392.261332 107.729733a283.236559 283.236559 0 0 0-244.999623 141.618279 16.286102 16.286102 0 0 0 5.664731 21.950834 15.578011 15.578011 0 0 0 8.497096 0 16.286102 16.286102 0 0 0 13.453737-7.789006A254.204811 254.204811 0 0 1 392.261332 143.134303a16.286102 16.286102 0 0 0 0-31.864113zM134.516063 315.908604a16.286102 16.286102 0 0 0-19.118467 12.037553 212.427419 212.427419 0 0 0-7.789006 55.939221 16.286102 16.286102 0 1 0 31.864113 0A178.439032 178.439032 0 0 1 143.01316 335.027071a16.286102 16.286102 0 0 0-8.497097-19.118467z m872.368601 545.230375l-257.745269-258.45336a75.765779 75.765779 0 0 0-19.118467-14.161828 391.574542 391.574542 0 1 0-128.872634 133.829274 84.970968 84.970968 0 0 0 12.745645 15.578011l257.037177 254.912903a95.592339 95.592339 0 0 0 135.245456-135.245457z m-613.915241-141.618279a327.138225 327.138225 0 1 1 327.138225-327.138226 327.846317 327.846317 0 0 1-327.846316 327.138226z m566.473117 231.545886a32.572204 32.572204 0 0 1-45.317849 0L658.503697 693.321318v-3.540457s0-7.080914 19.118467-25.49129 24.783199-19.118468 24.783199-19.118468h4.248549l257.745268 257.745268a31.864113 31.864113 0 0 1 9.205188 22.658925 31.864113 31.864113 0 0 1-12.037554 25.49129z" fill="#1296db" p-id="8744"></path></svg>
                </div>
                <div className="animate-ping absolute top-4 left-4 right-4 bottom-4 -z-10 bg-white/90 rounded-full shadow-xl p-3">
                </div>
            </div>
            {open &&
                <>
                    <div style={{ marginLeft: 0 }} className="fixed left-0 top-0 right-0 bottom-0 bg-white/30 z-30 w-full h-full" onClick={() => setOpen(false)}></div>
                    <div style={{ marginLeft: 0 }} className="animate-bottominmiddle rounded-lg bg-white w-4/5 h-1/2 fixed z-40 p-4">
                        <div className="text-lg">查询信息</div>
                        <div className="pt-3">
                            <div className="flex justify-center space-x-4">
                                <input type="text" className=" text-3xl rounded border h-16 w-2/3 flex-1 text-center" value={val} onChange={(e) => setVal(e.target.value)} />
                                {/* <button className="border rounded text-2xl px-6">查询</button> */}
                                <button disabled={isLoading || isFetching} onClick={submit} type="button" className="text-2xl rounded border border-primary-500 bg-primary-500 px-5 py-2.5 text-center text-white shadow-sm transition-all hover:border-primary-700 hover:bg-primary-700 focus:ring focus:ring-primary-200 disabled:cursor-not-allowed disabled:border-primary-300 disabled:bg-primary-300">查 询</button>
                                <button disabled={isLoading || isFetching} onClick={() => setVal('')} type="button" className="text-2xl rounded border border-gray-300 bg-white px-5 py-2.5 text-center font-medium text-gray-700 shadow-sm transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400">清 空</button>

                            </div>
                            <ShowCeHuDataView val={val} data={data} isLoading={isLoading} isError={isError} error={error} isFetching={isFetching} isRefetching={isRefetching} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const ShowCeHuDataView = ({ data, isLoading, isError, error, isFetching, isRefetching }: any) => {
    const notify = useNotify();

    if (isLoading || isFetching) {
        return (
            <div className="flex justify-center pt-20 flex-col items-center">
                <Sgv1 />
                <div className="text-green-500 text-2xl">数据查询中...</div>
            </div>
        )
    }
    if (isError) {
        return (
            <div className="flex justify-center pt-20 flex-col items-center">
                <Sgv1 />
                <div className="text-red-500 text-2xl">{`Error: ${error}`}</div>
            </div>
        )
    }
    if (data == undefined) return null;
    if (data == null || data.data == null || data.data.Result == null) {
        return (
            <div className="flex justify-center pt-20 flex-col items-center">
                <Sgv1 />
                <div className="text-red-500 text-2xl">没有查到到相关信息，请重试</div>
            </div>
        )
    }

    const V1 = () => {

        const th1 = "w-1/3 px-6 py-4 font-medium text-gray-500";
        const td1 = "px-6 py-4 font-medium text-gray-900";

        const { id, Num, name, mobile, address, idNumber, shebaoNumber,
            zaijiaqingkuang, naruguanli, shfoudabiao, shifajine, shifanhu,
            status, gaiceshijian, beizhu } = data.data.Result;
        return <div className="flex flex-col items-center justify-center mt-10">
            <table className=" border w-4/5 border-collapse bg-white text-left text-lg text-gray-500">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className={th1}>编号</th>
                        <th scope="col" className={th1}>姓名</th>
                        <th scope="col" className={th1}>身份编号</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    <tr>
                        <th className={td1}>{Num}</th>
                        <th className={td1}>{name}<br />{mobile}</th>
                        <th className={td1}>{idNumber}<br />{shebaoNumber}</th>
                    </tr>
                </tbody>
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className={th1}>进度状态</th>
                        <th scope="col" className={th1}>整改时间</th>
                        <th scope="col" className={th1}>实发金额</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    <tr>
                        <th className={td1}>{status}</th>
                        <th className={td1}>{gaiceshijian}</th>
                        <th className={td1}>{`${shifajine}元`}</th>
                    </tr>
                </tbody>
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className={th1}>纳入管理</th>
                        <th scope="col" className={th1}>是否达标</th>
                        <th scope="col" className={th1}>示范户</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    <tr>
                        <th className={td1}>{naruguanli ? '是' : '否'}</th>
                        <th className={td1}>{shfoudabiao ? '是' : '否'}</th>
                        <th className={td1}>{shifanhu ? '是' : '否'}</th>
                    </tr>
                </tbody>
                <thead className="bg-gray-50">
                    <tr>
                        <th colSpan={3} scope="col" className={th1}>备注</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-100 border-t border-gray-100">
                    <tr>
                        <th colSpan={3} className={td1}>{beizhu}</th>
                    </tr>
                </tbody>
            </table>
            <div className="flex justify-center space-x-5">
                <button onClick={() => print()} type="button" className=" mt-8 inline-flex items-center gap-1.5 rounded-lg border border-red-500 bg-red-500 px-5 py-2.5 text-center text-3xl text-white shadow-sm transition-all hover:border-red-700 hover:bg-red-700 focus:ring focus:ring-red-200 disabled:cursor-not-allowed disabled:border-red-300 disabled:bg-red-300">
                    <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7946" width="35" height="35"><path d="M592 562.2H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36zM592 753.7H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36z" fill="#FFF" p-id="7947"></path><path d="M950.4 138.5c-6.1-14.5-14.9-27.6-26.1-38.8-11.2-11.2-24.2-20-38.8-26.1-15.1-6.4-31.1-9.6-47.5-9.6H186c-16.4 0-32.4 3.2-47.5 9.6-14.5 6.1-27.6 14.9-38.8 26.1-11.2 11.2-20 24.2-26.1 38.8-6.4 15.1-9.6 31.1-9.6 47.5v332c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h70v198.5c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h268c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V640h70c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V186c0-16.4-3.2-32.4-9.6-47.5zM696 838.5c0 27.6-22.4 50-50 50H378c-27.6 0-50-22.4-50-50v-446h368v446zM888 518c0 27.6-22.4 50-50 50h-70V392.5h17c19.9 0 36-16.1 36-36s-16.1-36-36-36H239c-19.9 0-36 16.1-36 36s16.1 36 36 36h17V568h-70c-27.6 0-50-22.4-50-50V186c0-27.6 22.4-50 50-50h652c27.6 0 50 22.4 50 50v332z" fill="#FFF" p-id="7948"></path></svg>
                    <span>打 印</span>
                </button>
                {isDevelopment &&
                    <>
                        <button onClick={() => print(1)} type="button" className=" mt-8 inline-flex items-center gap-1.5 rounded-lg border border-stone-500 bg-stone-500 px-5 py-2.5 text-center text-3xl text-white shadow-sm transition-all hover:border-stone-700 hover:bg-stone-700 focus:ring focus:ring-stone-200 disabled:cursor-not-allowed disabled:border-stone-300 disabled:bg-stone-300">
                            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7946" width="35" height="35"><path d="M592 562.2H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36zM592 753.7H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36z" fill="#FFF" p-id="7947"></path><path d="M950.4 138.5c-6.1-14.5-14.9-27.6-26.1-38.8-11.2-11.2-24.2-20-38.8-26.1-15.1-6.4-31.1-9.6-47.5-9.6H186c-16.4 0-32.4 3.2-47.5 9.6-14.5 6.1-27.6 14.9-38.8 26.1-11.2 11.2-20 24.2-26.1 38.8-6.4 15.1-9.6 31.1-9.6 47.5v332c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h70v198.5c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h268c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V640h70c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V186c0-16.4-3.2-32.4-9.6-47.5zM696 838.5c0 27.6-22.4 50-50 50H378c-27.6 0-50-22.4-50-50v-446h368v446zM888 518c0 27.6-22.4 50-50 50h-70V392.5h17c19.9 0 36-16.1 36-36s-16.1-36-36-36H239c-19.9 0-36 16.1-36 36s16.1 36 36 36h17V568h-70c-27.6 0-50-22.4-50-50V186c0-27.6 22.4-50 50-50h652c27.6 0 50 22.4 50 50v332z" fill="#FFF" p-id="7948"></path></svg>
                            <span>预 览</span>
                        </button>
                        <button onClick={() => checkIsInstall()} type="button" className=" mt-8 inline-flex items-center gap-1.5 rounded-lg border border-stone-500 bg-stone-500 px-5 py-2.5 text-center text-3xl text-white shadow-sm transition-all hover:border-stone-700 hover:bg-stone-700 focus:ring focus:ring-stone-200 disabled:cursor-not-allowed disabled:border-stone-300 disabled:bg-stone-300">
                            <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7946" width="35" height="35"><path d="M592 562.2H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36zM592 753.7H432c-19.9 0-36-16.1-36-36s16.1-36 36-36h160c19.9 0 36 16.1 36 36 0 19.8-16.1 36-36 36z" fill="#FFF" p-id="7947"></path><path d="M950.4 138.5c-6.1-14.5-14.9-27.6-26.1-38.8-11.2-11.2-24.2-20-38.8-26.1-15.1-6.4-31.1-9.6-47.5-9.6H186c-16.4 0-32.4 3.2-47.5 9.6-14.5 6.1-27.6 14.9-38.8 26.1-11.2 11.2-20 24.2-26.1 38.8-6.4 15.1-9.6 31.1-9.6 47.5v332c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h70v198.5c0 16.4 3.2 32.4 9.6 47.5 6.1 14.5 14.9 27.6 26.1 38.8 11.2 11.2 24.2 20 38.8 26.1 15.1 6.4 31.1 9.6 47.5 9.6h268c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V640h70c16.4 0 32.4-3.2 47.5-9.6 14.5-6.1 27.6-14.9 38.8-26.1 11.2-11.2 20-24.2 26.1-38.8 6.4-15.1 9.6-31.1 9.6-47.5V186c0-16.4-3.2-32.4-9.6-47.5zM696 838.5c0 27.6-22.4 50-50 50H378c-27.6 0-50-22.4-50-50v-446h368v446zM888 518c0 27.6-22.4 50-50 50h-70V392.5h17c19.9 0 36-16.1 36-36s-16.1-36-36-36H239c-19.9 0-36 16.1-36 36s16.1 36 36 36h17V568h-70c-27.6 0-50-22.4-50-50V186c0-27.6 22.4-50 50-50h652c27.6 0 50 22.4 50 50v332z" fill="#FFF" p-id="7948"></path></svg>
                            <span>检 测</span>
                        </button>
                    </>
                }
            </div>
        </div>;
    }

    const checkIsInstall = (debug = true) => {
        try {
            // @ts-nocheck
            // @ts-ignore
            var LODOP = getLodop();
            if (LODOP == undefined || LODOP == null) { }
            else {
                if (LODOP && LODOP.VERSION) {
                    if (debug) {
                        if (LODOP.CVERSION)
                            alert("当前有WEB打印服务C-Lodop可用!\n C-Lodop版本:" + LODOP.CVERSION + "(内含Lodop" + LODOP.VERSION + ")");
                        else
                            alert("本机已成功安装了Lodop控件！\n 版本号:" + LODOP.VERSION);
                    }
                    return LODOP;
                };
            }
        } catch (err) {
            console.log('checkIsInstall err', err)
        }
        return null;
    };

    const print = (PREVIEW = 0) => {

        const { id, Num, name, mobile, address, idNumber, shebaoNumber,
            zaijiaqingkuang, naruguanli, shfoudabiao, shifajine, shifanhu,
            status, gaiceshijian, beizhu } = data.data.Result;

        console.log('Print');
        var Sign = parseInt(`${Math.random() * 1000000}`);
        try {
            // const strHtml =
            //     `
            //     <table border="1" style="border-collapse:collapse;border:solid 1px" bordercolor="#000000">
            //         <thead>
            //             <tr>
            //                 <th>编号</th>
            //                 <th>姓名</th>
            //                 <th>身份编号</th>
            //             </tr>
            //         </thead>
            //         <tbody>
            //             <tr>
            //                 <th>{Num}</th>
            //                 <th>{name}<br />{mobile}</th>
            //                 <th>{idNumber}<br />{shebaoNumber}</th>
            //             </tr>
            //         </tbody>
            //         <thead>
            //             <tr>
            //                 <th>进度状态</th>
            //                 <th>整改时间</th>
            //                 <th>实发金额</th>
            //             </tr>
            //         </thead>
            //         <tbody>
            //             <tr>
            //                 <th>{status}</th>
            //                 <th>{gaiceshijian}</th>
            //                 <th>--元</th>
            //             </tr>
            //         </tbody>
            //         <thead>
            //             <tr>
            //                 <th>纳入管理</th>
            //                 <th>是否达标</th>
            //                 <th>示范户</th>
            //             </tr>
            //         </thead>
            //         <tbody>
            //             <tr>
            //                 <th>{naruguanli ? '是' : '否'}</th>
            //                 <th>{shfoudabiao ? '是' : '否'}</th>
            //                 <th>{shifanhu ? '是' : '否'}</th>
            //             </tr>
            //         </tbody>
            //         <thead>
            //             <tr>
            //                 <th colSpan='3'>备注</th>
            //             </tr>
            //         </thead>
            //         <tbody>
            //             <tr>
            //                 <th colSpan='3'>{beizhu}</th>
            //             </tr>
            //         </tbody>
            //     </table>
            // `;

            const strHtml =
                `
<div>
    <h3>井研人居环境治理系统</h3>
</div>
<div style="line-height:25px;margin-top:30px">
    <div>编号：${Num}</div>
    <div>姓名：${name}</div>
    <div>手机：${mobile}</div>
    <div>身份证：${idNumber}</div>
    <div>社保号：${shebaoNumber}</div>
    <div>进度状态：${status}</div>
    <div>整改时间：${gaiceshijian}</div>
    <div>实发金额：${shifajine}元</div>
    <div>纳入管理：${naruguanli ? '是' : '否'}</div>
    <div>是否达标：${shfoudabiao ? '是' : '否'}</div>
    <div>示范户：${shifanhu ? '是' : '否'}</div>
    <div>备注：${beizhu}</div>
</div>
        `;

            const LODOP = checkIsInstall(false);
            if (LODOP == null) {
                console.log(`未检测到Lodop服务`);
                notify.show({ message: '未检测到Lodop服务', type: 'error' });
                return;
            }

            LODOP.PRINT_INIT(`打印任务:${Sign}`);
            LODOP.SET_PRINT_PAGESIZE(1, 720, 2100, "")
            // LODOP.SET_PRINT_MODE("POS_BASEON_PAPER",true);
            LODOP.ADD_PRINT_HTM(30, 15, "100%", "100%", strHtml);

            if (PREVIEW == 1) {
                console.log('Start Preview')
                LODOP.PREVIEW()
                return;
            }

            var pCount = LODOP.GET_PRINTER_COUNT();
            console.log('print count', pCount);
            if (pCount > 0) {
                for (var i = 0; i < pCount; i++) {
                    var deviceName = LODOP.GET_PRINTER_NAME(i) as string;
                    console.log('print index and name', i, deviceName);
                    if (deviceName.toLowerCase().indexOf("POS80".toLowerCase()) == 0) {
                        console.log('Find Print Device POS80 Success!');
                        if (LODOP.SET_PRINTER_INDEXA(i)) {
                            console.log('Start Print')
                            LODOP.PRINT()
                        }
                        else {
                            console.log('Set Print Device Fail!!!')
                        }
                        return;
                    }
                }
                notify.show({ message: '没有找到对应的打印设备[POS80]', type: 'error' });
            }
            else
                notify.show({ message: '没有找到任何打印设备', type: 'error' });
        } catch (e: any) {
            console.log(`打印服务出现异常，请检查设备是否开启 "Web打印服务CLodop"`, e);
            notify.show({ message: '打印服务出现异常，请检查设备是否开启 "Web打印服务CLodop"', type: 'error' });

        }
    }

    return (
        <V1 />
    )
}

export default Search;