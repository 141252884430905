import { useState } from "react";

const Quyu = ({ quyuId, quyuName, setQuYu, data }: any) => {
    let { XiangZhens } = data.data.Result;
    const [open, setOpen] = useState(false);

    XiangZhens = [{ id: 0, name: '全县' }, ...XiangZhens];

    const _setQuYu = ({ id, name }: any) => {
        setQuYu({ id, name });
        setOpen(false);
    }

    return (
        <>
            <div className=" relative shadow-xl xl:fixed xl:left-5 xl:top-5 xl:opacity-60" onClick={() => setOpen(true)}>
                <img className="h-14" src="/btn1.png" />
                <div className="absolute left-0 right-0 top-3.5 bottom-0 text-center text-xl text-white">区域：[{quyuName}]</div>
            </div>
            {open &&
                <>
                    <div style={{ marginLeft: 0 }} className="fixed left-0 top-0 right-0 bottom-0 bg-white/30 z-30 w-full h-full" onClick={() => setOpen(false)}></div>
                    <div style={{ marginLeft: 0 }} className="animate-bottominmiddle rounded-lg bg-white w-2/3 fixed z-40 p-4">
                        <div className="text-lg">选择区域</div>
                        <div className="pt-3">
                            <ul className="flex flex-wrap gap-4">
                                {XiangZhens.map(({ id, name }: any) => {
                                    return (<li onClick={() => _setQuYu({ id, name })} className={quyuId == id ? " bg-blue-400 text-white rounded border px-4 py-2 text-2xl text-center w-40 " : "rounded border px-4 py-2 text-2xl text-center w-40 "}>{name}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Quyu;