import React from 'react';
import logo from './logo.svg';
import './App.css';

function NoPage() {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <p className="mt-1 mx-auto text-gray-500">Page Not Found</p>
    </div>
  );
}

export default NoPage;
