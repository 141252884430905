

function Geming({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_CeSuo_Data : _r.dP_CeSuo_Datas.find((t: any) => t.XiangZhen_id == quyuId);

    const { cs_modi_shuliang,
        cs_gaizao_shuliang,
        cs_dabiao_shuliang,
        cs_butie_jine,
        mydc_canyudu,
        mydc_diaochawenjuan,
        mydc_manyidu,
        wnr_jihuabanqian,
        wnr_qitaxiangmu,
        wnr_buyuanyigai,
        wnr_yuqitagongyong,
        wnr_qitayuanyin,
        wancheng_2020qian,
        wancheng_2020,
        wancheng_2021,
        wancheng_2022,
        wancheng_2023hou,
        // wancheng,
        // gaizaozhong,
        gaizaomoshi_sangehua,
        gaizaomoshi_sanliantongzaoqi,
        gaizaomoshi_shangxiashui } = _d;
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">厕所革命</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="h-64 xl:h-52 relative">
                <div className="flex flex-col items-center absolute left-10 top-10">
                    <div className="text-2xl text-yellow-400">{cs_modi_shuliang}</div>
                    <div className="text-sm	">户厕摸底数量（个）</div>
                </div>
                <div className="flex flex-col items-center absolute right-10 top-10">
                    <div className="text-2xl text-purple-600">{cs_gaizao_shuliang}</div>
                    <div className="text-sm	">改造户厕数量（个）</div>
                </div>
                <div className="flex flex-col items-center absolute left-10 bottom-10">
                    <div className="text-2xl text-orange-400">{cs_dabiao_shuliang}</div>
                    <div className="text-sm	">已达标户厕（个）</div>
                </div>
                <div className="flex flex-col items-center absolute right-10 bottom-10">
                    <div className="text-2xl text-green-400">{((cs_butie_jine ?? 0) / 10000).toFixed(2)}</div>
                    <div className="text-sm	">补贴资金（万元）</div>
                </div>
                <div className="flex flex-col items-center absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]">
                    <div className="text-3xl text-green-400">{mydc_canyudu}%</div>
                    <div className="text-sm	">覆盖率</div>
                </div>
            </div>
            <div className='h-6 xl:h-1'></div>
        </div>
    )
};

export default Geming;