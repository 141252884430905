
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, AreaChart } from 'recharts';

function Weihuxuqiutongji({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_WeiGuan_Data : _r.dP_WeiGuan_Datas.find((t: any) => t.XiangZhen_id == quyuId);
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">管维护需求数量统计</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative h-72">
                <Tu _d={_d} />
            </div>
            <div className='h-6'></div>
        </div>
    )
};

function Tu({ _d }: any) {
    const {
        weiguan_count,
        weiguan_renyuancount,
        weiguan_jishixiangying,
        weiguan_chulijieguo,
        weiguan_fuwutaidu,
        xuqiu_weixiu,
        xuqiu_dusai,
        weiguan_choufen,
        weiguan_count_01,
        weiguan_count_02,
        weiguan_count_03,
        weiguan_count_04,
        weiguan_count_05,
        weiguan_count_06,
        weiguan_count_07,
        weiguan_count_08,
        weiguan_count_09,
        weiguan_count_10,
        weiguan_count_11,
        weiguan_count_12 } = _d;
    const data = [
        {
            name: '01月',
            amt: weiguan_count_01,
        },
        {
            name: '02月',
            amt: weiguan_count_02,
        },
        {
            name: '03月',
            amt: weiguan_count_03,
        },
        {
            name: '04月',
            amt: weiguan_count_04,
        },
        {
            name: '05月',
            amt: weiguan_count_05,
        },
        {
            name: '06月',
            amt: weiguan_count_06,
        },
        {
            name: '07月',
            amt: weiguan_count_07,
        },
        {
            name: '08月',
            amt: weiguan_count_08,
        },
        {
            name: '09月',
            amt: weiguan_count_09,
        },
        {
            name: '10月',
            amt: weiguan_count_10,
        },
        {
            name: '11月',
            amt: weiguan_count_11,
        },
        {
            name: '12月',
            amt: weiguan_count_12,
        },
    ];
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="">
                    <p className="label">{`数量: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="1 3" /> */}
                <XAxis dataKey="name" stroke="#FFF" />
                <YAxis stroke="#FFF" />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                {/* <Area barSize={15} dataKey="amt" stackId="a" fill="#8884d8" /> */}
                <Area type="monotone" name='数量' dataKey="amt" stackId="1" stroke="#00C43F" fill="#00C49F" />
            </AreaChart>
        </ResponsiveContainer>

    )
}

export default Weihuxuqiutongji;