

function Weihuzhuti({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_WeiGuan_Data : _r.dP_WeiGuan_Datas.find((t: any) => t.XiangZhen_id == quyuId);

    const {
        weiguan_count,
        weiguan_renyuancount,
        weiguan_jishixiangying,
        weiguan_chulijieguo,
        weiguan_fuwutaidu,
        xuqiu_weixiu,
        xuqiu_dusai,
        weiguan_choufen,
        weiguan_count_01,
        weiguan_count_02,
        weiguan_count_03,
        weiguan_count_04,
        weiguan_count_05,
        weiguan_count_06,
        weiguan_count_07,
        weiguan_count_08,
        weiguan_count_09,
        weiguan_count_10,
        weiguan_count_11,
        weiguan_count_12 } = _d;
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">管维护主体概况</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative mt-2">
                <div className="flex flex-row justify-evenly py-4">
                    <div className="flex flex-col items-center">
                        <div className=" relative flex flex-col items-center   justify-center " style={{ width: '116px', height: '116px' }}>
                            <div className="animate-spin_1 -z-10 absolute top-0 left-0" style={{ width: '116px', height: '116px', backgroundImage: 'url(/mk-1.png)', backgroundSize: 'cover' }}></div>
                            <div className=" text-3xl text-yellow-500">{weiguan_jishixiangying}</div>
                            <div>分</div>
                        </div>
                        <div className="mt-1 text-sm">及时响应</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="relative flex flex-col items-center   justify-center " style={{ width: '116px', height: '116px' }}>
                            <div className="animate-spin_2 -z-10 absolute top-0 left-0" style={{ width: '116px', height: '116px', backgroundImage: 'url(/mk-1.png)', backgroundSize: 'cover' }}></div>
                            <div className=" text-3xl text-yellow-500">{weiguan_chulijieguo}</div>
                            <div>分</div>
                        </div>
                        <div className="mt-1 text-sm">处理结果</div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="relative flex flex-col items-center   justify-center " style={{ width: '116px', height: '116px' }}>
                            <div className="animate-spin_3 -z-10 absolute top-0 left-0" style={{ width: '116px', height: '116px', backgroundImage: 'url(/mk-1.png)', backgroundSize: 'cover' }}></div>
                            <div className=" text-3xl text-yellow-500">{weiguan_fuwutaidu}</div>
                            <div>分</div>
                        </div>
                        <div className="mt-1 text-sm">服务态度</div>
                    </div>
                </div>
            </div>
            <div className='h-6'></div>
        </div>
    )
};

export default Weihuzhuti;