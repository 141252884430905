
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

function Weihuzanbi({ data, quyuId }: any) {
    var _r = data.data.Result;
    var _d = quyuId == 0 ? _r.Total_DP_WeiGuan_Data : _r.dP_WeiGuan_Datas.find((t: any) => t.XiangZhen_id == quyuId);
    return (
        <div className="">
            <div className="flex">
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75" />
                    </svg>
                </div>
                <div className="ml-1">管维护各类需求占比</div>
            </div>
            <div className="w-full mt-1 h-0.5 bg-[linear-gradient(to_right,#184195,#18419555)]"></div>
            <div className="relative h-72">
                <Tu _d={_d} />
            </div>
            <div className='h-12'></div>
        </div>
    )
};

function Tu({ _d }: any) {
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#0A6847'];
    const {
        weiguan_count,
        weiguan_renyuancount,
        weiguan_jishixiangying,
        weiguan_chulijieguo,
        weiguan_fuwutaidu,
        xuqiu_weixiu,
        xuqiu_dusai,
        weiguan_choufen,
        weiguan_count_01,
        weiguan_count_02,
        weiguan_count_03,
        weiguan_count_04,
        weiguan_count_05,
        weiguan_count_06,
        weiguan_count_07,
        weiguan_count_08,
        weiguan_count_09,
        weiguan_count_10,
        weiguan_count_11,
        weiguan_count_12 } = _d;
    const data02 = [
        { name: '地下部分维修', value: xuqiu_weixiu },
        { name: '抽粪', value: weiguan_choufen },
        { name: '管道堵塞', value: xuqiu_dusai },
    ];
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            console.log('payload', payload, label)
            return (
                <div className="">
                    <p className="label">{`${payload[0].name}: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };
    return (
        <>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Legend />
                    <Tooltip content={<CustomTooltip />} />
                    <Pie data={data02} dataKey="value" cx="50%" cy="50%" innerRadius={70} outerRadius={90} label >
                        {data02.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
            <div className="flex flex-col items-center absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%]">
                <div className="text-2xl text-purple-600">{xuqiu_weixiu + weiguan_choufen + xuqiu_dusai}个</div>
                <div className="text-sm	">总计</div>
            </div>
        </>
    )
}

export default Weihuzanbi;