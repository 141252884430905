import { useState } from "react";

const Ceguanhu = ({ chooseType, setChooseType }: any) => {
    return (
        <>
            <div className={`hidden relative shadow-xl xl:block ${chooseType === 2 ? 'opacity-100' : 'opacity-50'}`} onClick={() => { setChooseType(2) }}>
                <img className="h-14" src="/btn4.png" />
                <div className="absolute left-0 right-0 top-3.5 bottom-0 text-center text-xl text-white">户厕管维护</div>
            </div>
        </>
    )
}

export default Ceguanhu;